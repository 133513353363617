html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  height: 100vh;
  background-color: #6dc3b8;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.transition {
  transition: top 0.25s 0.05s;
  -webkit-transition: top 0.25s 0.05s;
}

textarea,
select,
input,
button,
button:hover,
button:focus {
  outline: none;
}

.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}

.slick-slide {
  padding-left: 8px;
}

.slick-dots li button:before {
  font-size: 0.8rem !important;
}

@font-face {
  font-family: "Bebas";
  src: url("./assets/Bebas.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue LT Std";
  src: url("./assets/HelveticaNeueLTStd-HvCn.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/Gotham-Bold.ttf");
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/Gotham-Light-Regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GothamBlack";
  src: url("./assets/Gotham-Black.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GothamBook";
  src: url("./assets/Gotham-Book.otf");
  font-weight: 400;
  font-style: normal;
}
